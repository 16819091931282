import Papa from 'papaparse'

export const parseCSV = async (file: string) => {
  const response = await fetch(file)
  const csvFile = await response.text()

  return new Promise<Papa.ParseResult<string[]>>((resolve, reject) => {
    Papa.parse(csvFile, {
      complete: resolve,
      error: reject,
      header: true,
    })
  })
}
