// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBLbRqVK64sQrevLvyKsq-Avw6tyRsglQU',
  authDomain: 'petagonia-next.firebaseapp.com',
  projectId: 'petagonia-next',
  storageBucket: 'petagonia-next.appspot.com',
  messagingSenderId: '376499965096',
  appId: '1:376499965096:web:925f65857c4f3f3fbac69d',
  measurementId: 'G-7MRW8YWKJW',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const dbh = getFirestore(app)
