import React, { useEffect } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

export const MyFallbackComponent: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const timeoutMilliseconds = 5000

  useEffect(() => {
    setTimeout(() => {
      // Here, we're simply logging the error after 2 seconds.
      // You can replace this with your own function.
      console.log('Error:', error)
      resetErrorBoundary()
    }, timeoutMilliseconds)
  }, [error, resetErrorBoundary])

  return (
    <div>
      <p>Something went wrong (reloading in {timeoutMilliseconds / 1000} seconds):</p>
      <div>{error.message}</div>
    </div>
  )
}

export const MyErrorBoundaryComponent = (props: any) => {
  const { children } = props

  return (
    <ErrorBoundary
      FallbackComponent={MyFallbackComponent}
      onReset={() => {
        window.location.reload()
      }}
    >
      {children}
    </ErrorBoundary>
  )
}
