// https://github.com/DavidWells/next-with-react-router-v6#route-switcher

import { ErrorBoundary } from 'react-error-boundary'

import { makeStyles } from '@material-ui/core/styles'

import { useFirebaseAuth } from 'hooks/useFirebaseAuth'
import { lazyImport } from 'utils/importing'
import ReloadButton from 'components/ReloadButton'
import { MyErrorBoundaryComponent } from 'errors/MyFallbackComponent'
import { CircularProgress } from '@material-ui/core'

const { ProtectedRoutes } = lazyImport(() => import('./ProtectedRoutes'), 'ProtectedRoutes')
const { PublicRoutes } = lazyImport(() => import('./PublicRoutes'), 'PublicRoutes')

const useStyles = makeStyles(
  theme => ({
    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'MultiLineTextField' }
)

export const AppRoutes = () => {
  const classes = useStyles()
  console.log('AppRoutes')
  const { user, loading } = useFirebaseAuth()

  console.log('USER: ', user)
  console.log('USER LOADING: ', loading)

  if (loading) {
    console.warn('AppRoutes > Loading')
  }

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : user ? (
    // <ErrorBoundary
    //   fallback={
    //     <div>
    //       Something went wrong: Protected <ReloadButton />
    //     </div>
    //   }
    // >
    <MyErrorBoundaryComponent>
      <ProtectedRoutes user={user} loading={loading} />
    </MyErrorBoundaryComponent>
  ) : (
    // </ErrorBoundary>
    <ErrorBoundary fallback={<div>Something went wrong: Public</div>}>
      <PublicRoutes />
    </ErrorBoundary>
  )
}
