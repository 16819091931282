import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom'
import { AppRoutes } from './routes/AppRoutes'
import { AppContainer } from './containers/AppContainer'
import CSVDataGrid from 'examples/csv/CSVDataGrid'
import CSVTable from 'examples/csv/CSVTable'
import { TableView } from 'pages/views/TableView'

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route index element={<Navigate to="/views/table" />} />
          <Route path="/*" element={<AppContainer />}>
            {/* <Route path="/homepage" element={<Homepage />} /> */}

            {/* Generic Views */}
            <Route path="views">
              <Route path="table" element={<TableView />} />
            </Route>

            {/* Examples for testing new libraries */}
            <Route path="examples">
              {/* <Route path="firebase" element={<FirebaseSignIn />} />
              <Route path="firebase-image" element={<FirebaseSignInWithImageButton />} />
              <Route path="google" element={<ReactOauthGoogleSimple />} />
              <Route path="homepage" element={<SimpleHomepage />} /> */}
              <Route path="datagrid" element={<CSVDataGrid />} />
              <Route path="table" element={<CSVTable />} />
            </Route>

            {/* Protected and Public Routes start here */}
            <Route path="*" element={<AppRoutes />} />
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
