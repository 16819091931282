import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import Papa from 'papaparse'

import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import { parseCSV } from 'utils/parsing'
import { isUnknownOrUndefined } from 'utils/values'

// import initialCsvFile from 'assets/csv/ps2_grades.csv'
import initialCsvFile from 'assets/csv/empty.csv'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },

    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },

    tableHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: theme.palette.grey[200],
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
    },

    tableHeaderRow: {
      fontWeight: 'bold',
    },

    tableHeaderCell: {
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'lightyellow',
      },
    },

    tableHeaderCellContent: {
      display: 'flex',
      flex: 1,
      // justifyContent: 'center',
      alignItems: 'center',
    },

    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:hover': {
        backgroundColor: 'lightyellow',
      },
    },

    firstColumn: {
      position: 'sticky',
      left: 0,
      zIndex: 5,
      backgroundColor: 'white',
      boxShadow: '3px 0 5px rgba(0, 0, 0, 0.15)', // drop shadow effect to the right
    },

    topLeftCellContents: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },

    username: {
      fontFamily: 'monospace',
    },
  }),
  { name: 'CSVTable' }
)

interface CSVRow {
  [key: string]: string
}

const columnHeaderMap: Record<string, string> = {
  pointsActual: 'Actual',
  pointsMax: 'Max',
}

export const TableView = () => {
  const classes = useStyles()

  // Statuses
  const [loading, setLoading] = useState<boolean>(true)
  const [dirty, setDirty] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const [data, setData] = useState<Papa.ParseResult<string[]> | null>(null)

  const [sortBy, setSortBy] = useState<string>('section')
  const [sortAscending, setSortAscending] = useState<boolean>(true)

  const [csvFile, setCsvFile] = useState<string>(initialCsvFile)

  const [numLines, setNumLines] = useState<number>(0)

  const [filename, setFilename] = useState<string>(initialCsvFile.split('/').pop())

  useEffect(() => {
    async function fetchData() {
      try {
        const parsedData = await parseCSV(csvFile)
        setNumLines(parsedData.data.length)
        setData(parsedData)
        setDirty(true)
      } catch (error) {
        console.error('Error parsing CSV:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    // Sort the data by sortBy and direction
    if (data) {
      const sortedData = data.data.sort((a, b) => {
        const aValue = (a as Record<string, any>)[sortBy]
        const bValue = (b as Record<string, any>)[sortBy]

        // Check if sortBy starts with "grade" and sort numerically
        if (sortBy.startsWith('grades') || sortBy.startsWith('section')) {
          // Parse aValue and bValue as numbers before comparison
          const numericAValue = parseFloat(aValue)
          const numericBValue = parseFloat(bValue)

          return sortAscending ? numericAValue - numericBValue : numericBValue - numericAValue
        } else {
          // Perform localeCompare for non-numeric sorting
          return sortAscending
            ? String(aValue).localeCompare(String(bValue))
            : String(bValue).localeCompare(String(aValue))
        }
      })

      setData({
        ...data,
        data: sortedData,
      })
      setDirty(false)
      setLoading(false)
    }
  }, [sortBy, sortAscending, dirty])

  const renderStyle = (field: string, value: string) => {
    switch (field) {
      case 'score':
        return {
          backgroundColor: value === '0' ? 'pink' : 'transparent',
        }
      case 'similarity':
        return {
          backgroundColor: value.includes('FAIL') ? 'pink' : 'transparent',
        }
      default:
        if (/^grade/.test(field)) {
          const defaultStyles = {
            backgroundColor: isUnknownOrUndefined(value) ? 'pink' : 'transparent',
          }
          switch (value) {
            case '0':
              return {
                fontWeight: 'bold',
                backgroundColor: 'pink',
              }
            default:
              return defaultStyles
          }
        } else {
          return {
            backgroundColor: isUnknownOrUndefined(value) ? 'pink' : 'transparent',
          }
        }
    }
  }

  const renderValue = (field: string, value: string) => {
    switch (field) {
      case 'email':
      case 'feedback':
      case 'githubUsername':
      case 'similarity':
      case 'debug':
        return (
          <div>
            <pre>
              <code>{value}</code>
            </pre>
          </div>
        )

      case 'sourceUrl':
        return (
          <a href={value} target="_blank" rel="noopener noreferrer">
            source
          </a>
        )
      case 'repoName':
        return renderGithubUrl(value)

      default:
        return value
    }
  }

  const renderGithubUrl = (repoName: string) => {
    // console.info('TABLE > renderGithubUrl > ROW: ', row)
    return (
      <a
        href={`https://github.com/LMU-CMSI-1010/${repoName}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {repoName}
      </a>
    )
  }

  const handleHeaderClick = (header: string) => {
    setSortBy(header)

    // If the header is already the sortBy, then toggle the sortAscending
    if (header === sortBy) {
      setSortAscending(!sortAscending)
    } else {
      setSortAscending(true)
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true)
    const file = event.target.files && event.target.files[0]

    // console.log('FILE: ', file)

    if (file) {
      try {
        const parsedData = await parseUploadedCSV(file)
        setData(parsedData)
        setNumLines(parsedData.data.length)
        setFilename(file.name)
        setDirty(true)
        setUploading(false)
      } catch (error) {
        console.error('Error parsing CSV:', error)
      }
    }
  }

  const parseUploadedCSV = (file: File): Promise<Papa.ParseResult<string[]>> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: resolve,
        error: reject,
        header: true,
      })
    })
  }

  // console.info('TABLE > sortBy: ', sortBy)
  // console.info('TABLE > typeof initialCsvFile: ', typeof initialCsvFile)

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <Helmet>
        <title>{filename}</title>
      </Helmet>
      <Table size="small" padding="checkbox" stickyHeader={false}>
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableHeaderRow}>
            <TableCell key="#" className={classes.tableHeaderCell}>
              <div className={classes.topLeftCellContents}>
                <input
                  type="file"
                  id="csv-upload"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".csv"
                />
                <label htmlFor="csv-upload">
                  <Tooltip title={filename}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={uploading}
                    >
                      {uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                    </Button>
                  </Tooltip>
                </label>
                <div>{numLines} rows</div>
              </div>
            </TableCell>

            {/* Assume headers are in the first row of the data */}
            {data?.meta.fields?.map((header, index) => (
              <TableCell
                key={index}
                className={classes.tableHeaderCell}
                onClick={e => handleHeaderClick(header)}
              >
                <div className={classes.tableHeaderCellContent}>
                  {columnHeaderMap[header] || header}

                  {/* {header === 'feedback' && <>&nbsp;&middot;&nbsp;{filename}</>} */}

                  {header === sortBy && sortAscending && <ArrowDropUpIcon />}
                  {header === sortBy && !sortAscending && <ArrowDropDownIcon />}
                </div>
              </TableCell>
            ))}
            {/* <TableCell key="githubUsername" className={classes.tableHeaderCell}>
              githubUsername
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data && data.data.length > 0 ? (
            data?.data.map((row, rowIndex) => {
              return (
                <TableRow className={classes.tableRow} key={rowIndex}>
                  <TableCell className={classes.firstColumn} key="#">
                    {rowIndex + 1}
                  </TableCell>
                  {data.meta.fields?.map((field, cellIndex) => {
                    const value = (row as Record<string, any>)[field]
                    return (
                      <TableCell key={cellIndex} style={renderStyle(field, value)}>
                        {renderValue(field, value)}
                      </TableCell>
                    )
                  })}
                  {/* <TableCell key="githubUsername2" className={classes.username}>
                  {(row as Record<string, any>).githubUsername}
                </TableCell> */}
                </TableRow>
              )
            })
          ) : (
            <TableRow
              style={
                {
                  // fontWeight: 'bold',
                  // display: 'flex',
                  // flex: 1,
                  // justifyContent: 'center',
                }
              }
            >
              <TableCell
                colSpan={10}
                align="center"
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                }}
              >
                Upload a CSV results file from the MetaGrader script.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
