import React, { useState, ChangeEvent, useEffect } from 'react'
import { DataGrid, GridCellParams, GridColDef, GridRowData } from '@material-ui/data-grid'
import Papa from 'papaparse'

import csvFile from 'assets/csv/Lab10-2023-03-22-20-19-13.csv'

import './CSVDataGrid.css'

interface Props {
  //   onFileChange: (event: ChangeEvent<HTMLInputElement>) => void
}

type ColumnAttributesMap = {
  [key: string]: object
}

const columnAttributesMap: ColumnAttributesMap = {
  id: {
    flex: 1,
  },
  section: {
    flex: 1,
  },
  studentLabPath: {
    flex: 2,
  },
  pointsActual: {
    flex: 1,
  },
  pointsMax: {
    flex: 1,
  },
  score: {
    flex: 1,
  },
  cellsFound: {
    flex: 1,
  },
  feedback: {
    flex: 1,
    renderCell: (params: GridCellParams) => {
      // Ensure that the value is a string before rendering
      const value = typeof params.value === 'string' ? params.value : ''
      return (
        <div className="feedbackCell">
          <pre>
            <code>{value}</code>
          </pre>
        </div>
      )
    },
  },
  similarity: {
    flex: 1,
  },
  webUrl: {
    flex: 1,
  },
  sourceUrl: {
    flex: 1,
  },
}

const CSVDataGrid: React.FC<Props> = () => {
  const [rows, setRows] = useState<GridRowData[]>([])
  const [columns, setColumns] = useState<GridColDef[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(csvFile)
        const data = await response.text()

        Papa.parse(data, {
          header: true,
          dynamicTyping: true,
          complete: result => {
            if (result.data) {
              // Add index field to each row
              const processedData = result.data.map((row, index) => ({
                id: index,
                ...(row as Record<string, any>),
              }))

              console.log('processedData: ', processedData)

              //   const sampleRow = result.data[0] || {}
              const sampleRow = processedData[0]
              console.log('sampleRow: ', JSON.stringify(sampleRow, null, 2))

              const columnDefs = Object.keys(sampleRow).map((key, index) => {
                const baseColumnDef = {
                  field: key,
                  headerName: key,
                  width: 150,
                }

                // If the key exists in columnAttributesMap, merge the attributes
                if (columnAttributesMap[key]) {
                  return {
                    ...baseColumnDef,
                    ...columnAttributesMap[key],
                  }
                }
                return baseColumnDef
              })
              setColumns(columnDefs)

              setRows(processedData as GridRowData[])
            }
          },
        })
      } catch (error) {
        console.error('Error fetching or parsing CSV:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <div
      style={{
        // height: 400,
        width: '100%',
      }}
    >
      {/* <input type="file" onChange={handleFileChange} /> */}
      <DataGrid className="dataGrid" rows={rows} columns={columns} pageSize={50} />
    </div>
  )
}

export default CSVDataGrid
